import React, { useCallback, useState } from 'react';
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import AllInboxIcon from "@mui/icons-material/AllInbox";
import Typography from "@mui/material/Typography";
import OutboxIcon from "@mui/icons-material/Outbox";
import IconButton from '@mui/material/IconButton';
import SortIcon from '@mui/icons-material/Sort';
import EmailIcon from "@mui/icons-material/Email";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import ReplyIcon from '@mui/icons-material/Reply';
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import {styled} from "@mui/material/styles";
import {css} from "@mui/system";
import {cl} from '@twipped/utils';
import EmailModal from "common/Email";
import moment from 'moment'
import debounce from 'lodash.debounce';
import Menu from '@mui/material/Menu';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import Check from '@mui/icons-material/Check';
import Tooltip from '@mui/material/Tooltip';
import { useSearchParams } from 'react-router-dom';

const NotesAndMessages = styled('div')(({ theme }) => (css`
  .MuiGrid-root>.MuiGrid-item {
    padding-top: 8px;
  }
  h5 {
    font-size: .8rem;
    i {
      font-style: normal;
      float: right;
      color: ${theme.palette.primary.main};
    }
  }
  h6 {
    font-size: .8rem;
    color: ${theme.palette.primary.main};
  }
  .MuiTypography-body1 {
    font-weight: 500;
    font-size: .8rem;
  }
  .MuiCardContent-root:last-child {
    padding-bottom: 16px;
  }
  .MuiCard-root {
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
    border-radius: 0;
    box-shadow: none;
    margin-bottom: 1px;
    &:hover {
      background-color: #f4f9fd;
      cursor: pointer;
    }
  }
  .main-content {
      min-height: 60vh;
      margin-top: 2px;
    &:hover {
      background-color: #fff;
      cursor: default;
    }
    .reply {
      border-radius: 16px;
      margin-top: 2rem;
    }
  }
  .MuiList-root {
    margin: 8px 0;
    li {
      padding: 6px 0;
      font-size: .85rem;
      font-weight: 500;
      &:hover {
        color: ${theme.palette.primary.main};
        .MuiListItemIcon-root{
          color: ${theme.palette.primary.main};
        }
      }
    }
    .Mui-selected {
      color: ${theme.palette.primary.main};
      .MuiListItemIcon-root{
        color: ${theme.palette.primary.main};
      }
    }
  }
  .MuiInputBase-input, .MuiFormLabel-root, .MuiSelect-select{
    font-size: .8rem;
    &:focus {
      background: transparent;
    }
  }
  .unread-email {
    font-weight: 600;
  }
  .selected-card {
    background-color: #f4f9fd;
  }
  .empty-box {
    background-color: #fff !important;
    height: 90%;
    text-align: center;
    padding-top: 50%;
  }
`),
);

export default function NotesAndMessagesTab ({ clientDetail }) {

  const [showEmailModal, setShowEmailModal] = useState(false);
  const [selectedMenuItem, setSelectedMenuItem] = useState(0);
  const [selectedEmailItem, setSelectedEmailItem] = useState(null);
  const [contentItem, setContentItem] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState(null);
  const [menuItems, setMenuItems] = useState([]);
  const [inboxList, setInboxList] = useState([]);
  const [outboxList, setOutboxList] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [sortBy, setSortBy] = useState(0);
  const [sortDirection, setSortDirection] = useState(false);
  const [fromValue, setFromValue] = useState(null);
  const [toValue, setToValue] = useState(null);
  
  const TRIGGER_PARAM = 'orderDetail';
  const [ searchParams ] = useSearchParams();
  const orderid = searchParams.get(TRIGGER_PARAM);

  const sortMenuList = [
    {
      id: 'date',
      label: 'Date',
      directions: [
        {label: 'Oldest on top', value: false},
        {label: 'Newest on top', value: true},
      ]
    },
    {
      id: 'from_name',
      label: 'Sender',
      directions: [
        {label: 'A to Z', value: true},
        {label: 'Z to A', value: false},
      ]
    },
  ]

  React.useEffect(() => {
    setMenuItems([
      {
        id: 'inbox',
        text: "INBOX",
        icon: <AllInboxIcon fontSize="small"/>,
        selected: true,
        unread: 4,
      },
      {
        id: 'outbox',
        text: "SENT ITEM",
        icon: <OutboxIcon fontSize="small" />,
        selected: false
      },
      // {
      //   text: "NOTES",
      //   icon: <TextSnippetIcon fontSize="small" />,
      //   selected: false
      // },
      // {
      //   text: "ALERT",
      //   icon: <NotificationsActiveIcon fontSize="small" />,
      //   selected: false
      // },
    ]);
    var date = new Date();
    setInboxList([
      {
        id: 110,
        subject: "Attorney Opinion Letter",
        from_email: "jsmith@legaleagles.com",
        from_name: "John Smith",
        context: "Test1 Good afternoon",
        date: date.toString(),
        unread: true
      },
      {
        id: 112,
        subject: "Attorney Opinion Letter",
        from_email: "jsmith@legaleagles.com",
        from_name: "John Smith",
        context: "I should have this file back this.",
        date: new Date(date.setMinutes(date.getMinutes() - 1)).toString(),
        unread: true
      },
      {
        id: 113,
        subject: "Attorney Opinion Letter",
        from_email: "jsmith@legaleagles.com",
        from_name: "John Smith",
        context: "afternoon.",
        date: new Date(date.setMinutes(date.getMinutes() - 1)).toString(),
        unread: true
      },
      {
        id: 114,
        subject: "Attorney Opinion Letter",
        from_email: "test@legaleagles.com",
        from_name: "Test",
        context: "Test2 Good afternoon, I should have this file back this. afternoon.",
        date: new Date(date.setHours(date.getHours() - 1)).toString(),
        unread: true
      },
      {
        id: 115,
        subject: "Attorney Opinion Letter",
        from_email: "abcd@legaleagles.com",
        from_name: "ABCD",
        context: "Test3 Good afternoon",
        date: new Date(date.setHours(date.getHours() + 2)).toString(),
        unread: false
      },
      {
        id: 116,
        subject: "Attorney Opinion Letter",
        from_email: "abcd@legaleagles.com",
        from_name: "ABCD",
        context: "I should have this file back this. afternoon.",
        date: new Date(date.setHours(date.getHours() + 5)).toString(),
        unread: false
      }
    ])
    setOutboxList([
      {
        id: 210,
        subject: "Attorney Opinion Letter",
        from_email: "jsmith@legaleagles.com",
        from_name: "John Smith",
        context: "Send Item testing",
        date: date.toString(),
        unread: false
      }
    ])
  }, [])

  const openEmailModal = (from_value=null, to_value=null) => {
    setFromValue(from_value)
    setToValue(to_value)
    setShowEmailModal(true)
  }

  const closeEmailModal = () => {
    setShowEmailModal(false)
  }

  const onClickMenuItem = (index) => {
    setSelectedMenuItem(index)
    setSelectedEmailItem(null)
    setContentItem(null)
  }

  const onClickMarkAllRead = () => {
    let newMenuItems = menuItems.slice()
    if (newMenuItems[selectedMenuItem].unread)
      newMenuItems[selectedMenuItem].unread = null
    setMenuItems(newMenuItems)
    let newInboxList = inboxList.slice()
    for (let email of newInboxList) {
      email.unread = false
    }
    setInboxList(newInboxList)
  }

  const onClickEmailItem = (index, item) => {
    let emails = selectedMenuItem == 0 ? inboxList : outboxList
    if (emails[index].unread) {
      let newMenuItems = menuItems.slice()
      if (newMenuItems[selectedMenuItem].unread > 1)
        newMenuItems[selectedMenuItem].unread -= 1
      else
        newMenuItems[selectedMenuItem].unread = null
      setMenuItems(newMenuItems)

      let newInboxList = inboxList.slice()
      newInboxList[index].unread = false
      setInboxList(newInboxList)
    }
    setSelectedEmailItem(index)
    setContentItem(item)
  }

  const open = Boolean(anchorEl);
  const showFilterMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const closeFilterMenu = () => {
    setAnchorEl(null);
  };

  const compare = ( a, b ) => {
    const sortField = sortMenuList[sortBy].id
    if ( a[sortField] < b[sortField] ){
      if (sortDirection) return -1; else return 1;
    }
    if ( a[sortField] > b[sortField] ){
      if (sortDirection) return 1; else return -1;
    }
    return 0;
  }

  const filterBySearch = (items) => {
    return items.filter(o =>
        Object.keys(o).some(k => typeof o[k] === 'string' && o[k].toLowerCase().includes(searchKeyword.toLowerCase())));
  }

  const hightlightSearchKeywords = (content) => {
    if (!searchKeyword || !content)
      return content;

    const pos = content.toLowerCase().indexOf(searchKeyword.toLowerCase());
    if (pos < 0)
      return content

    return (
      <Typography variant="span">
        {content.substr(0, pos)}
        <Typography variant="span" sx={{background: 'yellow', fontWeight: 'bold'}}>{content.substr(pos, searchKeyword.length)}</Typography>
        {content.substr(pos+searchKeyword.length)}
      </Typography>
    )
  }

  const showEmailList = () => {
    let emails = selectedMenuItem == 0 ? inboxList : outboxList
    if (searchKeyword) {
      emails = filterBySearch(emails)
    }
    
    if (emails.length == 0)
      return <Box className='empty-box'>Nothing to read</Box>

    emails.sort(compare);

    return emails.map((item, index) => (
      <Card className={cl(index==selectedEmailItem && 'selected-card')} key={"item"+index} onClick={() => onClickEmailItem(index, item)}>
        <CardContent>
          <Typography variant="h5" className={cl(item.unread && 'unread-email')}>
            {hightlightSearchKeywords(item.from_name)}
            <i>{moment(new Date(item.date)).format('MM/DD/YY hh:mm a')}</i>
          </Typography>
          <Typography variant="h6">
            {hightlightSearchKeywords(item.subject)}
          </Typography>
          {item.context.length > 50 ?
            <Typography>
              {hightlightSearchKeywords(item.context.substring(0, 50))}...
            </Typography>
            :
            <Typography>
              {hightlightSearchKeywords(item.context)}
            </Typography>
          }
        </CardContent>
      </Card>
    ))
  }

  const searchHandler = useCallback( async (event) => {
    if(event.target.value.length < 3)
    {
      setSearchKeyword(null)
      return;
    }
    setSearchKeyword(event.target.value)
    setContentItem(null)
  });
  
  const onkeyup = useCallback(debounce(searchHandler,1000),[]);
  
  return (
    <NotesAndMessages>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <Button variant="contained" startIcon={<AddIcon />} onClick={() => openEmailModal()}>NEW EMAIL</Button>
          <MenuList>
            {menuItems.map((item, index) => (
              <MenuItem selected={index==selectedMenuItem} key={item.text} name="inbox" id="inbox" onClick={() => onClickMenuItem(index)}>
                <ListItemIcon color='primary'>
                  {item.icon}
                </ListItemIcon>
                <Typography variant="inherit">{item.text}</Typography>
                {item.unread && <Typography variant="inherit" sx={{position: 'absolute', right: '12px'}}>{item.unread}</Typography>}
              </MenuItem>
            ))}
          </MenuList>
        </Grid>
        <Grid item xs={4}>
          <Box sx={{display: 'flex', position: 'relative', height: '54px'}}>
            {selectedMenuItem == 0 &&
              <Button sx={{height: '37px'}} variant="contained" startIcon={<EmailIcon />} onClick={onClickMarkAllRead}>MARK ALL AS READ</Button>
            }
            <Box sx={{ position: 'absolute', right: 0, top: 5}}>
              <Tooltip title="Filter">
                <IconButton
                  onClick={showFilterMenu}
                  size="small"
                  aria-controls={open ? 'filter-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                >
                  <SortIcon sx={{color: "#0e5fd4", cursor: "pointer"}}/>
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
          {showEmailList()}
        </Grid>
        <Grid item xs={6}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField id="standard-basic" label="Search Messages" fullWidth variant="standard" onKeyUp={onkeyup}/>
            </Grid>
            {contentItem != null &&
              <Grid item xs={12} sx={{marginTop: '18px', marginLeft: '16px', background: '#fff5f2'}}>
                <Typography variant="h5" sx={{marginBottom: '8px'}}>
                  {hightlightSearchKeywords(contentItem.subject)}
                </Typography>
                <Typography variant="h5">
                  {hightlightSearchKeywords(contentItem.from_name)} ({hightlightSearchKeywords(contentItem.from_email)})
                </Typography>
                <Typography variant="h6">
                  {contentItem.date}
                </Typography>
              </Grid>
            }
            <Grid item xs={12}>
              <Card className='main-content'>
                {contentItem != null &&
                  <CardContent>
                    <Typography>
                      {hightlightSearchKeywords(contentItem.context)}
                    </Typography>
                    <Button variant="outlined" className='reply' onClick={() => openEmailModal('help@voxtur.com', `help-${orderid}@voxtur.com`)}>
                      <ReplyIcon/>Reply
                    </Button>
                  </CardContent>
                }
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={closeFilterMenu}
        onClick={closeFilterMenu}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuList dense>
          {sortMenuList.map((option, index) => (
            <MenuItem value={option.id} key={option.id} onClick={() => setSortBy(index)}>
              <ListItemIcon>{sortBy == index && <Check />}</ListItemIcon>
              {option.label}
            </MenuItem>
          ))}
          <Divider />
          <MenuItem value="ascending" key="ascending" onClick={() => setSortDirection(sortMenuList[sortBy].directions[0].value)}>
            <ListItemIcon>{sortDirection == sortMenuList[sortBy].directions[0].value && <Check />}</ListItemIcon>
            {sortMenuList[sortBy].directions[0].label}
          </MenuItem>
          <MenuItem value="descending" key="descending" onClick={() => setSortDirection(sortMenuList[sortBy].directions[1].value)}>
            <ListItemIcon>{sortDirection == sortMenuList[sortBy].directions[1].value && <Check />}</ListItemIcon>
            {sortMenuList[sortBy].directions[1].label}
          </MenuItem>
        </MenuList>
      </Menu>
      {showEmailModal && <EmailModal open={showEmailModal} handleClose={closeEmailModal} client={clientDetail} from_value={fromValue} to_value={toValue}/>}
    </NotesAndMessages>
  );
}
