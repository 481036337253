import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import Draggable from "react-draggable";
import Typography from "@mui/material/Typography";
import InputBase from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';
import { alpha, styled } from '@mui/material/styles';
import SendIcon from '@mui/icons-material/Send';
import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend';
import { css } from '@mui/system';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import {stateFromHTML} from 'draft-js-import-html';
import useAuthentication from 'common/authentication';
import useCompany from 'common/company';
import Vendor from '../models/vendor';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const style = {
  overflowY: "auto",
  minWidth: "50%",
  bgcolor: "background.paper",
  border: 0,
  outline: 0,
  boxShadow: 24,
};

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  width: '100%',
  'label + &': {
    marginBottom: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
    border: '1px solid #ced4da',
    fontSize: 16,
    width: '100%',
    padding: '10px 12px',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

const InputLabelBox = styled(InputLabel)(
  ({ theme }) => (css`
    color: ${theme.palette.primary.main};
    text-transform: uppercase;
  `)
);

const FromBox = styled(Box)(
  ({ theme }) => (css`
    p {
      font-size: 14px;
      margin-top: 4px;
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
    .full-name {
      color: black;
      font-weight: bold;
    }
  `)
);

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} sx={{pointerEvents: 'auto'}}/>
    </Draggable>
  );
}

const EmailModal = ({ open, handleClose, from_value, to_value }) => {
  const { user } = useAuthentication();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [subject, setSubject] = useState("Subject Line")
  const [fromValue, setFromValue] = useState(from_value)
  const [toValue, setToValue] = useState(to_value)

  useEffect(() => {
    let html = '<p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p>';
    // html += `<h4>${user.name.full}</h4>`;
    // html += `<p>E: ${user.email}</p>`;
    // html += `<p>www.voxtur.com</p>`;
    let contentState = stateFromHTML(html);
    setEditorState(EditorState.createWithContent(contentState));
  }, []);

  const { branding } = useCompany();
  const api = (new Vendor);

  const sendEmail = async () => {
    try {
      if (fromValue) {
        const text = draftToHtml(convertToRaw(editorState.getCurrentContent()))
        const result = await api.sendEmail({
          "from": from_value,
          "to": toValue,
          "subject": subject,
          "text": text
        });
        handleClose(true);
      }
    }
    catch (err) {
      console.log(err)
    }
  }

  return (
    <Dialog
      hideBackdrop
      disableEnforceFocus
      sx={{ position: 'sticky', pointerEvents: 'none'}}
      open={open}
      onClose={handleClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      disableBackdropClick
    >
        <DialogTitle
          sx={{
            cursor: "move",
            color: "#fff",
            "&.MuiTypography-root": {
              fontFamily: "Inter",
            },
            pt: 3,
            pb: 0
          }}
          id="draggable-dialog-title"
        >
          <Box>
            <InputLabelBox shrink htmlFor="send-to">
              Send To
            </InputLabelBox>
            <BootstrapInput
              readOnly={toValue ? true : false}
              defaultValue={toValue ? toValue: ""}
              />
          </Box>
          <Box>
            <InputLabelBox shrink htmlFor="subject-line">
              Subject
            </InputLabelBox>
            <BootstrapInput
              id="subject-line"
              onChange={(e) => setSubject(e.target.value)}
              value={subject}
            />
          </Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Box sx={{ border: "1px solid lightgrey", p: 2, minHeight: '300px' }}>
              <Editor
                editorState={editorState}
                wrapperClassName="demo-wrapper"
                editorClassName="demo-editor"
                onEditorStateChange={(es) => setEditorState(es)}
              />
              <FromBox>
                <Typography className="full-name">{user.name.full}</Typography>
                {fromValue &&<Typography>E: {fromValue}</Typography>}
                {user.phone &&<Typography>P: {user.phone}</Typography>}
                <Box
                    sx={{
                        flexGrow: 1,
                        backgroundPosition: 'left',
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        backgroundImage: `url(/common/companyfiles.php?logo=1&filename=${branding.logoPrimary})`,
                        height: 30,
                        marginTop: '36px'
                    }}
                    />
                <Typography>www.voxtur.com</Typography>
              </FromBox>
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ px: 4 }}>
          <Button
            variant="outlined"
            sx={{mr: 2, minWidth: '150px'}}
            onClick={() => handleClose(true)}
          >
            <CancelScheduleSendIcon sx={{mr: 2}}/>
            Cancel
          </Button>
          <Button 
            variant="contained"
            color="primary"
            onClick={sendEmail}
          >
            <SendIcon  sx={{mr: 2}}/>
            Send Email
          </Button>
        </DialogActions>
    </Dialog>
  );
};

export default EmailModal;
