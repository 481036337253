import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { styled } from "@mui/material/styles";
import { css } from "@mui/system";

import OrderPartsSubPage from './partList';
import AccountingTab from './accountingTab';
import NotesAndMessagesTab from './notesAndMessagesTab';
import OrderManagementTab from "./orderManagementTab";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const TabBox = styled('div')(({ theme }) => (css`
    .MuiTabs-root{
      background: ${theme.palette.primary.main};
      padding: 0 30px;
    }
    .MuiTab-root{
      color: #a3baea;
      margin: 0 10px;
    }
    .Mui-selected{
      color: #fff;
    }
    MuiTabs-indicator{
      background: #fff;
    }
    #simple-tabpanel-1{
      .MuiBox-root{
        background: #f2f2f2;
      }
    }
    #simple-tabpanel-2{
      .MuiBox-root{
        padding: 0;
      }
    }
  `), );



export default function OrderDetailsSubPage(props) {
  const [value, setValue] = React.useState(0);
  const [clientDetail, setClientDetail] = React.useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (<>
    <TabBox>
      <Box>
        <Tabs value={value} onChange={handleChange} aria-label="wrapped label tabs example">
          <Tab label="Order Management" {...a11yProps(0)} />
          <Tab label="Notes & Messages" {...a11yProps(1)} />
          {/* <Tab label="Accounting" {...a11yProps(2)} /> */}
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <OrderManagementTab {...props} setClientDetail={setClientDetail}/>
        <OrderPartsSubPage {...props}/>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <NotesAndMessagesTab clientDetail={clientDetail}/>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <AccountingTab />
      </TabPanel> 
    </TabBox>
  </>);
}
