import React, { useEffect } from 'react';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import LoadingButton from '@mui/lab/LoadingButton';
import { css } from "@mui/system";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useParams } from "react-router-dom";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import moment from "moment";
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
//
import usePipelineServer from '../../../common/pipelines/usePipelineServer';
import usePipeline from "../../../common/pipelines/usePipeline";
import { Api } from "../../../core/api/base";
import Order from '../../../common/models/order';
import CustomTag from "../../../components/custom-tag";
import './styles.scss';

const ButtonMargin = styled(Button)(css`
    margin: 10px
`);

const LoadingButtonMargin = styled(LoadingButton)(css`
    margin: 20px 10px 0px
`);

const ContainerBox = styled('div')(css`
  display: inline-block;
  width: 100%;
  margin-bottom: 50px;
  .MuiTextField-root{
    margin: 8px 0;
  }
  .MuiInputBase-input, .MuiFormLabel-root, .MuiSelect-select{
    font-size: .8rem;
    &:focus{
      background: transparent;
    }
  }
`);


export default function OrderManagementTab({ orderid, setClientDetail }) {

  const { orderid: orderIdParam, orderdata: orderDataParam } = useParams(),
    [orderDetail, setOrderDetail] = React.useState(null),
    [orderDetailFetching, setOrderDetailFetching] = React.useState(true),
    [toastState, setToastState] = React.useState({ open: false, message: "", severity: 'success' }),
    [updatingOrder, setUpdatingOrder] = React.useState(false),
    [orderTags, setOrderTags] = React.useState([]),
    api = (new Order);

  const { refresh } = usePipelineServer();

  orderid = Number(orderid || orderIdParam);

  // console.log("refresh ....",usePipelineServer())

  const { pipeline } = usePipeline();
  const orderdata = pipeline.getRow(orderid);
  const order = orderdata && orderdata.json;

  // if (!order) return null

  // const [propertyModal, setPropertyModal] = React.useState(order?.property);
  // const [dateModal, setDateModal] = React.useState(order?.date);
  // const [clientModal, setClientModal] = React.useState(order?.client);

  useEffect(() => {
    setOrderDetailFetching(true)
    getOrderDetail(orderid)
    getOrderTags()
  }, [orderid])

  useEffect(() => {
    if (toastState.open) {
      setTimeout(() => {
        toastMessage()
      }, 3000)
    }
  }, [toastState.open])

  const getOrderDetail = async (orderId) => {
    try {
      const result = await api.getOrderDetail(orderId);
      const { status, order } = result;
      if (status === "success") {
        setOrderDetail({ ...order, tags: order?.tags?.map(x => x.id) || [] });
        setClientDetail({ email: order.client_email, name: order.client_name })
        toastMessage(true, "Order detail fetched successfully", "success")
      }
    }
    catch (err) {
      toastMessage(true, err.message || "Error while load data", "error")
    }
    finally {
      setOrderDetailFetching(false)
    }
  }

  const getOrderTags = async () => {
    try {
      const result = await api.getOrderTags();
      const { status, tags } = result;

      if (status === "success") {
        setOrderTags(tags);
        toastMessage(true, "Order detail fetched successfully", "success")
      }
    }
    catch (err) {
      toastMessage(true, err.message || "Error while load data", "error")
    }
    finally {
      setOrderDetailFetching(false)
    }
  }

  const onChangeSelectBox = (e) => {
    const { value } = e.target;

    setOrderDetail((prevState) => {
      const tags = prevState.tags || []
      tags.push(value)
      return { ...prevState, tags }
    })
  }

  const onChangeText = (e) => {
    const { value, name } = e.target

    setOrderDetail(prevState => {
      return {
        ...prevState,
        [name]: value
      }
    })
  }

  const handleUpdateOrder = async () => {
    setUpdatingOrder(true);
    const result = await api.updateOrder(orderDetail);

    if (result.status == "success") toastMessage(true, "Order detail has been updated!", "success");
    else if (result.status == "fail") toastMessage(true, result.error, "error");
    else toastMessage(true, 'Request Fail', "error");

    setUpdatingOrder(false)
    refresh()
  }

  const toastMessage = (open = false, message, severity = "success") => {
    setToastState({
      open,
      message,
      severity
    })
  }


  const handleRemoveTag = (id) => {
    setOrderDetail((prevState) => {
      return {
        ...prevState,
        tags: prevState.tags.filter(x => x !== id)
      }
    })
  };

  return (
    <ContainerBox className="testClass">
            <Snackbar
                open={toastState.open}
                autoHideDuration={3000}
            >
                <Alert variant="filled" severity={toastState.severity}>
                    {toastState.message}
                </Alert>
            </Snackbar>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Typography variant="h4" component="h4" style={{ padding: "15px 0" }}>
                        Order Information
                    </Typography>
                </Grid>
          {/*     <Grid item xs={6} style={{ textAlign: "right" }}>
                    <ButtonMargin variant="contained" disabled>PUT ORDER ON HOLD</ButtonMargin>
          <ButtonMargin variant="contained" disabled>CANCEL ORDER</ButtonMargin>
                </Grid> */}
            </Grid>

            {orderDetailFetching
                ? <Box sx={{ display: 'flex' }}>
                    <CircularProgress />
                </Box>
        :<>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                id="standard-textarea"
                                label="STREET ADDRESS"
                                placeholder="STREET ADDRESS"
                                variant="standard"
                                name={"propertyaddress"}
                                // defaultValue={orderDetail?.propertyaddress}
                                value={orderDetail?.propertyaddress}
                                onChange={onChangeText}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth variant="standard" className="orderTagWrapper">
                                <InputLabel id="multiple-name-label">Order Tags</InputLabel>
                                <Select
                                    labelId="multiple-name-label"
                                    id="demo-simple-select"
                                    value={orderDetail?.tags?.toString() || []}
                                    label="Order Tags"
                                    onChange={onChangeSelectBox}
                                >
                                    {orderTags.map((x, i) => (
                                        <MenuItem
                                            disabled={orderDetail?.tags?.indexOf(x.id) !== -1}
                                            key={i}
                                            value={x.id}>
                                            {x.label}
                                        </MenuItem>
                                    ))}
                                </Select>

                                <Stack direction="row" spacing={1} className="tags">
                                    {orderDetail?.tags.map((x, i) => {
                                        const tag = orderTags.find(y => y.id == x)
                                        return <CustomTag
                                            key={i}
                                            label={tag?.label || "Deleted"}
                                            onDelete={handleRemoveTag}
                                            color={tag?.color}
                                            id={x}
                                        />
                                    })}
                                </Stack>
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <TextField
                                fullWidth
                                id="standard-textarea1"
                                label="CITY"
                                placeholder="CITY"
                                variant="standard"
                                value={orderDetail?.propertycity}
                                name={"propertycity"}
                                onChange={onChangeText}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <TextField
                                fullWidth
                                id="standard-textarea2"
                                label="STATE"
                                placeholder="STATE"
                                variant="standard"
                                value={orderDetail?.propertystate}
                                name={"propertystate"}
                                onChange={onChangeText}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <TextField
                                fullWidth
                                id="standard-textarea2"
                                label="ZIP CODE"
                                placeholder="ZIP CODE"
                                variant="standard"
                                value={orderDetail?.propertyzipcode}
                                name="propertyzipcode"
                                onChange={onChangeText}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="Date&Time picker"
                                    value={orderDetail?.ordereddate}
                                    inputFormat="MM/dd/yyyy"
                                    disabled
                                    onChange={(e) => {
                                        onChangeText({ target: { name: "due_date", value: e } })
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            fullWidth
                                            id="standard-textarea2"
                                            label="DATE ORDERED"
                                            placeholder="DATE ORDERED"
                                            variant="standard"
                                            disabled
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={2}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="Date&Time picker"
                                    value={orderDetail?.due_date}
                                    inputFormat="MM/dd/yyyy"
                                    onChange={(e) => {
                                        onChangeText({ target: { name: "due_date", value: e } })
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            fullWidth
                                            id="standard-textarea2"
                                            label="DATE DUE"
                                            placeholder="DATE DUE"
                                            variant="standard"
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={2}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="Date&Time picker"
                                    error={false}
                                    value={orderDetail?.escrow_close}
                                    inputFormat="MM/dd/yyyy"
                                    defaultValue={null}
                                    onChange={(e) => {
                                        onChangeText({ target: { name: "escrow_close", value: e } })
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            fullWidth
                                            id="standard-textarea2"
                                            label="ESCROW CLOSE"
                                            placeholder="ESCROW CLOSE"
                                            variant="standard"
                                            error={false}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Typography variant="h4" component="h4" style={{ padding: "15px 0" }}>
                                Client Information
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="h4" component="h4" style={{ padding: "15px 0" }}>
                                Loan Information
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <TextField
                                        fullWidth
                                        id="standard-textarea1"
                                        label="CLIENT NAME"
                                        placeholder="CLIENT NAME"
                                        variant="standard"
                                        name={"client_name"}
                                        value={orderDetail?.client_name}
                                        // onChange={onChangeText}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        fullWidth
                                        id="standard-textarea2"
                                        label="CLIENT CONTACT"
                                        placeholder="CLIENT CONTACT"
                                        variant="standard"
                                        name="client_phone"
                                        value={orderDetail?.client_phone}
                                        // onChange={onChangeText}
                                        disabled
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <TextField
                                        fullWidth
                                        id="standard-textarea2"
                                        label="CLIENT PHONE"
                                        placeholder="CLIENT PHONE"
                                        variant="standard"
                                        name="client_phone"
                                        value={orderDetail?.client_phone}
                                        // onChange={onChangeText}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        fullWidth
                                        id="standard-textarea1"
                                        label="CLIENT EMAIL"
                                        placeholder="CLIENT EMAIL"
                                        variant="standard"
                                        name="client_email"
                                        value={orderDetail?.client_email}
                                        // onChange={onChangeText}
                                        disabled
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <TextField
                                        fullWidth
                                        id="standard-textarea2"
                                        label="ESCROW NUMBER"
                                        placeholder="ESCROW NUMBER"
                                        variant="standard"
                                        name="escrow_num"
                                        value={orderDetail?.escrow_num}
                                        onChange={onChangeText}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        fullWidth
                                        id="standard-textarea2"
                                        label="LOAN REFERENCE  NO."
                                        placeholder="LOAN REFERENCE  NO."
                                        variant="standard"
                                        name="loanreference"
                                        value={orderDetail?.loanreference}
                                        onChange={onChangeText}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                            <Grid item xs={6}>
                                    <TextField
                                        fullWidth
                                        id="standard-textarea2"
                                        label="BORROWER NAME"
                                        placeholder="BORROWER NAME"
                                        variant="standard"
                                        name="borrower_name"
                                        value={orderDetail?.borrower_name}
                                        onChange={onChangeText}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        fullWidth
                                        id="standard-textarea2"
                                        label="SELLER NAME"
                                        placeholder="SELLER NAME"
                                        variant="standard"
                                        name="contact_name"
                                        value={orderDetail?.contact_name}
                                        onChange={onChangeText}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{ textAlign: "right" }}>
                          <LoadingButtonMargin variant="contained" onClick={handleUpdateOrder} loading={updatingOrder}>Update Order Information</LoadingButtonMargin>
                        </Grid>
                    </Grid>
                </>
            }
        </ContainerBox>
  );
}
