import React from 'react';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {styled} from "@mui/material/styles";
import {css} from "@mui/system";

const Accounting = styled('div')(({ theme }) => (css`
    .chart-box{
      background-color: #f2f2f2;
    }
    .MuiGrid-spacing-xs-2{
      padding: 8px 24px;
    }
    .MuiTypography-customTage{
      span{
        display: block;
        width: 100%;
        color: ${theme.palette.primary.main};
        font-size: 12px;
        margin-top: 10px;
      }
    }
    hr{
      width: 100%;
      border-color: #bababa;
      border-top: 0;
      border-left: 0;
      border-right: 0;
      margin: 20px 0;
    }
    h6{
      font-size: 14px;
    }
   h4{
     margin: 8px 0 50px 0;
   }
   .MuiTypography-body1{
     padding: 10px 0;
     font-size: 14px;
   }
 `),
);

export default function AccountingTab ({ orderid }) {
  return(
    <Accounting>
      <Grid container>
        <Grid item xs={9} style={{ padding: '24px' }}>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="h4">
                Accounting Summary
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Button variant="contained" style={{ float: "right" }}>DOWNLOAD INVOICE</Button>
            </Grid>
            <Grid item xs={5} style={{ textAlign: "center" }}>
              <Typography variant="h6">
                Accounts Receivable
              </Typography>
              <hr/>
              <Grid container spacing={2}>
                <Grid xs={6}>
                  <Typography variant="customTage">
                    <span>DATE</span>
                    1/31/2022
                  </Typography>
                </Grid>
                <Grid xs={6}>
                  <Typography variant="customTage">
                    <span>INVOICE AMOUNT</span>
                    $896.00
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={5} style={{ textAlign: "center" }}>
              <Typography variant="h6">
                Payments/Credits
              </Typography>
              <hr/>
              <Grid container spacing={2}>
                <Grid xs={6}>
                  <Typography variant="customTage">
                    <span>DATE</span>
                    1/31/2022
                  </Typography>
                </Grid>
                <Grid xs={6}>
                  <Typography variant="customTage">
                    <span>AMOUNT</span>
                    $896.00
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={2} style={{ textAlign: "center" }}>
              <Typography variant="h6">
                Balance Due
              </Typography>
              <hr/>
              <Grid container spacing={2}>
                <Grid xs={12}>
                  <Typography variant="customTage">
                    <span>NET TOTAL</span>
                    $0
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container style={{ paddingTop: '60px' }}>
            <Grid item xs={12}>
              <Typography variant="h4">
                Vendor & Provider Payables
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography variant="h6">
                Document
              </Typography>
              <hr/>
              <Typography>
                Seller Statement of Information
              </Typography>
              <Typography>
                Attorney Opinion Letter
              </Typography>
              <Typography>
                Notary Services
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="h6">
                Vendor Name
              </Typography>
              <hr/>
              <Typography>
                Harry Homeower
              </Typography>
              <Typography>
                John Smith
              </Typography>
              <Typography>
                Nick Notary
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="h6">
                Payable Firm
              </Typography>
              <hr/>
              <Typography>
                Seller
              </Typography>
              <Typography>
                Legal Sercices, Inc.
              </Typography>
              <Typography>
                Notaries by Nick
              </Typography>
            </Grid>
            <Grid item xs={2} style={{ textAlign: "right" }}>
              <Typography variant="h6">
                Amount
              </Typography>
              <hr/>
              <Typography>
                $0
              </Typography>
              <Typography>
                $375.00
              </Typography>
              <Typography>
                $95.00
              </Typography>
            </Grid>
            <Grid item xs={7}></Grid>
            <Grid item xs={3}>
              <hr/>
              <Typography variant="h6">
                Total
              </Typography>
            </Grid>
            <Grid item xs={2} style={{ textAlign: "right" }}>
              <hr/>
              <Typography variant="h6">
                $470.00
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={3} className='chart-box'>
          Chart
        </Grid>

      </Grid>
    </Accounting>
  );
}
